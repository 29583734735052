/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { Dispatch } from '@reduxjs/toolkit';
// import makeStore from '../redux/store';
import { Routes } from 'common/router-name';
// import { useAppDispatch } from '../redux/hooks';
import { logout } from '../redux/features/authSlice';

type dataPropsType = FormData | string | object | any;

const defaultHeaders = {
  'Content-Type': 'application/json'
};

interface CustomApiError {
  message: string;
  data: Record<string, any>;
  code?: number;
}

class CustomError extends Error {
  data: Record<string, any>;

  constructor({ message, data = {} }: CustomApiError) {
    super(message);
    this.data = data;

    // Set the prototype explicitly to ensure correct behavior
    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

async function apiInvokeWithRefresh(
  url: string,
  method: string,
  body?: dataPropsType,
  headers?: Record<string, string>,
  token?: string | null | undefined,
  dispatch?: any
) {
  const accessToken = token || localStorage.getItem('token');

  if (!accessToken) {
    dispatch(logout());
    localStorage.clear();
    throw new CustomError({
      message: 'Access token not found',
      data: {}
    });
  }

  try {
    // Attempt the API call with the provided access token
    return apiInvoke(url, method, body, headers, accessToken, dispatch);
  } catch (error: any) {
    // If the error is due to an expired token, attempt to refresh the token and retry the API call
    if (error.data.code === 401 || error === 'Invalid token.') {
      // const newAccessToken = await refreshToken();
      // Retry the original API call with the new access token
      // return await apiInvoke(url, method, body, headers, newAccessToken);

      dispatch(logout());
      localStorage.clear();
    }
    // If the error is not due to token expiration, rethrow the original error
    throw error;
  }
}

async function apiInvoke(
  url: string,
  method: string,
  body?: dataPropsType,
  headers?: Record<string, string>,
  token?: string | null | undefined,
  dispatch?: any
) {
  let mainUrl = process.env.REACT_APP_API;
  headers = headers || {};
  headers.Authorization = token ? `Bearer ${token}` : '';
  if (url.startsWith('/')) {
    mainUrl = `${mainUrl}${url.substring(1)}`;
  } else {
    mainUrl = `${mainUrl}${url}`;
  }

  const r = await fetch(`${mainUrl}`, {
    method: method ?? 'GET',
    headers: {
      ...(headers ?? {})
    },
    body
  });

  const data: Record<string, any> = await r.json();
  const error: CustomError = new CustomError({
    message:
      data?.error || data?.message || data?.detail || 'Something went wrong',
    code: r.status,
    data
  });
  error.data = data;

  if (!r.ok) {
    const error: CustomError = new CustomError({
      message:
        data?.error || data?.message || data?.detail || 'Something went wrong',
      code: data.code,
      data
    });

    if (r.status === 401) {
      dispatch(logout());
      localStorage.clear();
    }
    throw error;
  }

  return data;
}

async function get_api(
  url: string,
  dispatch?: any,
  token?: string | null | undefined
) {
  token = localStorage.getItem('token');
  try {
    return apiInvokeWithRefresh(
      `${url}`,
      'GET',
      undefined,
      defaultHeaders,
      token,
      dispatch
    );
  } catch (error: any) {
    return error;
  }
}

async function post_api(
  url: string,
  data: dataPropsType,
  dispatch?: any,
  token?: string | null | undefined
) {
  const headers = data instanceof FormData ? {} : defaultHeaders;
  const newData = data instanceof FormData ? data : JSON.stringify(data);
  token = localStorage.getItem('token');
  try {
    return apiInvoke(`${url}`, 'POST', newData, headers, token, dispatch);
  } catch (error) {
    dispatch(logout());
    return;
  }
}

async function put_api(
  url: string,
  data: dataPropsType,
  dispatch?: any,
  token?: string | null | undefined
) {
  const headers = data instanceof FormData ? {} : defaultHeaders;
  const newData = data instanceof FormData ? data : JSON.stringify(data);
  token = localStorage.getItem('token');
  try {
    return apiInvoke(`${url}`, 'PUT', newData, headers, token, dispatch);
  } catch (error) {
    dispatch(logout());
    throw error;
  }
}

async function patch_api(
  url: string,
  data: dataPropsType,
  dispatch?: any,
  token?: string | null | undefined
) {
  const headers = data instanceof FormData ? {} : defaultHeaders;
  const newData = data instanceof FormData ? data : JSON.stringify(data);
  token = localStorage.getItem('token');
  try {
    return apiInvoke(`${url}`, 'PATCH', newData, headers, token, dispatch);
  } catch (error) {
    dispatch(logout());
    return;
  }
}

async function delete_api(
  url: string,
  dispatch?: any,
  token?: string | null | undefined
) {
  // const headers = data instanceof FormData ? {} : defaultHeaders;
  // const newData = data instanceof FormData ? data : JSON.stringify(data);
  const headers = defaultHeaders;
  token = localStorage.getItem('token');
  try {
    return apiInvoke(`${url}`, 'DELETE', null, headers, token, dispatch);
  } catch (error) {
    dispatch(logout());
    return;
  }
}

async function logout_api(url: string, data: dataPropsType) {
  let mainUrl = process.env.NEXT_PUBLIC_MAIN_URL;
  if (url.startsWith('/')) {
    mainUrl = `${mainUrl}${url.substring(1)}`;
  } else {
    mainUrl = `${mainUrl}${url}`;
  }
  const response = await fetch(`${mainUrl}`, {
    method: 'POST',
    body: JSON.stringify(data)
  });

  if (response.ok) {
    return {
      data: null,
      status: true,
      message: 'Logout successful'
    };
  } else {
    return {
      data: null,
      status: false,
      message: 'Logout failed'
    };
  }
}

export { get_api, post_api, put_api, patch_api, delete_api, logout_api };
