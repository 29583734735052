/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import Cookies from "js-cookie";
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { loginApi } from '../api/auth';
import { credentialsProps } from 'interfaces/auth.interface';
import {
  PendingUserQueObjectProps,
  PendingUserQueResult
} from 'pages/dashboard/pending-user-query/pendintUqeInterface';

const initialState: PendingUserQueObjectProps = {
  status: 'idle',
  count: 0,
  next: '',
  previous: '',
  pages: 0,
  results: []
};

export const dsCallGetEvent = createAsyncThunk(
  'dsCall/get',
  async (data: PendingUserQueObjectProps) => {
    return data;
  }
);

export const dsCallListUpdate = createAsyncThunk(
  'dsCall/dsCallListUpdate',
  async (info: PendingUserQueResult) => {
    return info;
  }
);

export const dsCallRemoveFromListUpdate = createAsyncThunk(
  'dsCall/dsCallRemoveFromListUpdate',
  async (info: string) => {
    return info;
  }
);

export const dsCallDispatcherJoinUpdateWaitTime = createAsyncThunk(
  'dsCall/dsCallDispatcherJoinUpdateWaitTime',
  async (data: { type: string; info: PendingUserQueResult }) => {
    return data;
  }
);

export const dsCallDispatcherJoinRemoveData = createAsyncThunk(
  'dsCall/dsCallDispatcherJoinRemoveData',
  async (info: PendingUserQueResult) => {
    return info;
  }
);

export const dsCall = createSlice({
  name: 'dsCall',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(dsCallGetEvent.pending, state => {
        state.status = 'loading';
      })
      .addCase(
        dsCallGetEvent.fulfilled,
        (state, action: PayloadAction<PendingUserQueObjectProps>) => {
          state.status = 'succeeded';
          state.count = action.payload.count;
          state.pages = action.payload.pages;
          state.next = action.payload.next;
          state.previous = action.payload.previous;
          state.results = action.payload.results;
        }
      )
      .addCase(dsCallGetEvent.rejected, state => {
        state = initialState;
        state.status = 'failed';
      })
      .addCase(dsCallListUpdate.pending, state => {
        state.status = 'loading';
      })
      .addCase(
        dsCallListUpdate.fulfilled,
        (state, action: PayloadAction<PendingUserQueResult>) => {
          const exists = state.results.some(
            (item: PendingUserQueResult) =>
              item.call_id === action.payload.call_id
          );
          console.log('exists: ', exists);
          if (!exists) {
            if (state.results.length === 20) {
              return {
                ...state,
                status: 'succeeded',
                count: state.count + 1,
                results: [action.payload, ...state.results.slice(0, -1)]
              };
            }
            return {
              ...state,
              status: 'succeeded',
              count: state.count + 1,
              results: [
                action.payload,
                ...(Array.isArray(state.results) ? state.results : [])
              ]
            };
          } else {
            // return state;
            const updatedDataArray = state.results.map(item => {
              if (item.call_id === action.payload.call_id) {
                // Update the object here
                return action.payload;
              }
              return item; // Return the object as is if call_id does not match
            });

            return {
              ...state,
              results: updatedDataArray
            };
          }
        }
      )
      .addCase(dsCallListUpdate.rejected, state => {
        state.status = 'failed';
      })
      .addCase(dsCallRemoveFromListUpdate.pending, state => {
        state.status = 'loading';
      })
      .addCase(
        dsCallRemoveFromListUpdate.fulfilled,
        (state, action: PayloadAction<string>) => {
          const filteredResults = state.results.filter(
            (result: PendingUserQueResult) => result.call_id !== action.payload
          );
          localStorage.removeItem('end_call');
          return {
            ...state,
            status: 'succeeded',
            count: state.count - 1 ?? 0,
            results: filteredResults
          };
        }
      )
      .addCase(dsCallRemoveFromListUpdate.rejected, state => {
        state = initialState;
        state.status = 'failed';
      })
      .addCase(dsCallDispatcherJoinRemoveData.pending, state => {
        state.status = 'loading';
      })
      .addCase(
        dsCallDispatcherJoinRemoveData.fulfilled,
        (state, action: PayloadAction<PendingUserQueResult>) => {
          const filteredResults = state.results.filter(
            (result: PendingUserQueResult) =>
              result.call_id !== action.payload.call_id
          );
          return {
            ...state,
            status: 'succeeded',
            count: state.count - 1 ?? 0,
            results: filteredResults
          };
        }
      )
      .addCase(dsCallDispatcherJoinRemoveData.rejected, state => {
        state = initialState;
        state.status = 'failed';
      })
      .addCase(dsCallDispatcherJoinUpdateWaitTime.pending, state => {
        state.status = 'loading';
      })
      .addCase(
        dsCallDispatcherJoinUpdateWaitTime.fulfilled,
        (state, action: PayloadAction<any>) => {
          const updatedResults = state.results.map(
            (result: PendingUserQueResult) => {
              if (
                action.payload.type === 'dispatcher_joined' &&
                result.call_id === action.payload.info.call_id
              ) {
                return {
                  ...result,
                  wait_time: '00:00:00'
                };
              }
              return result;
            }
          );
          return {
            ...state,
            status: 'succeeded',
            results: updatedResults
          };
        }
      )
      .addCase(dsCallDispatcherJoinUpdateWaitTime.rejected, state => {
        state.status = 'failed';
      });
  }
});

export default dsCall.reducer;
